<section class="container mb-4">
  <section class="row my-4">
    <!-- Mobile only Order Total -->
    <section
      class="col-md-12 py-4 u-border-top-grey d-flex align-items-center justify-content-center justify-content-md-between flex-column flex-md-row d-block d-sm-none"
    >
      <ng-container *ngIf="!stdCartType">
        <h3 class="font-bold mb-0">
          {{ totalInfluenzaProducts }}
        </h3>
        <h1 class="font-bold mb-0">
          {{ totalInfluenzaDoses }} {{ 'plp.doses' | cxTranslate }}
        </h1>
      </ng-container>
      <ng-container *ngIf="stdCartType; else fluCartOrderTotal">
        <h3 class="font-bold mb-0">
          {{ 'addToCartItems.orderTotal' | cxTranslate }}:
        </h3>
        <h1 class="font-bold mb-0">
          {{ standardCartDetails?.totalPriceWithTax?.value | currency }}
        </h1>
      </ng-container>
      <ng-template #fluCartOrderTotal>
        <h3 class="font-bold mb-0">
          {{ 'addToCartItems.estTotal' | cxTranslate }}:
        </h3>
        <h1 class="font-bold mb-0">
          {{ standardCartDetails?.totalPriceWithTax?.value | currency }}
        </h1>
      </ng-template>
    </section>
    <!-- Buttons -->
    <section
      class="col-md-12 d-flex justify-content-around justify-content-md-end mb-4 d-block d-sm-none"
    >
      <button
        class="c-button c-button--secondary mr-md-3"
        (click)="navigateToCart()"
      >
        {{ 'addToCartItems.editCart' | cxTranslate }}
      </button>
      <button class="c-button c-button--primary" (click)="checkout()">
        {{ 'addToCartItems.continue' | cxTranslate }}
      </button>
    </section>
    <!-- Terms and condition -->
    <section class="col-md-12 mb-4 u-border-bottom-grey pb-4 d-block d-sm-none">
      <div *ngIf="stdCartType; else fluCartTerm">
        <app-terms-conditions compName="TandC-checkout"></app-terms-conditions>
      </div>
      <ng-template #fluCartTerm>
        <app-terms-conditions
          compName="TandC-fluseasonviewpayment"
        ></app-terms-conditions>
      </ng-template>
    </section>
    <!-- Mobile only total end-->

    <!-- Main content -->
    <div class="col-md-8 u-border-right-grey pr-md-4 pr-lg-5">
      <section class="row pb-4 u-border-bottom-grey">
        <div class="col-md-8 mb-2">
          <ng-template #fluPayment>
            <section class="checkOut-Header">
              <span class="checkOut-Header-text">{{
                'addToCartItems.verifyPayment' | cxTranslate
              }}</span>
              <section
                class="float-right sm-float-left p-left-flu"
                *ngIf="estimatedDate != ''"
              >
                <p class="fw-bold text_indent">
                  {{ 'addToCartItems.deliveryDate' | cxTranslate
                  }}<span class="light-color fw-normal ml1">{{
                    estimatedDate | slice : 0 : 10 | date : dateFormat
                  }}</span>
                </p>
              </section>
            </section>
          </ng-template>
          <div
            class="firstLevel-acc checkout-heading-acc"
            *ngIf="stdCartType; else fluPayment"
          >
            <ng-template #deliveryLoc>
              <i class="fa fa-map-marker-alt font-n"></i>
              <span class="px-3 text-fixed-medium"
                >{{ selectedData?.companyName }}
                {{ '(' + selectedData?.unitID + ')' }}</span
              >
            </ng-template>
            <div
              *ngIf="standardCartDetails?.pickupDate; else deliveryLoc"
              class="pickupLocation"
            >
              <h6>{{ 'addToCartItems.pickLocation' | cxTranslate }}</h6>
              <p>
                {{ 'addToCartItems.sanofiName' | cxTranslate }} <br />{{
                  'addToCartItems.locationLine1' | cxTranslate
                }}<br />{{ 'addToCartItems.locationLine2' | cxTranslate }}
              </p>
            </div>
          </div>
        </div>
      </section>

      <!-- Shipping -->
      <section class="row pt-4 text-fixed-medium">
        <section
          class="col-md-4"
          *ngIf="standardCartDetails?.pickupDate === undefined"
        >
          <h3 class="font-bold">{{ 'addToCartItems.shipTo' | cxTranslate }}</h3>
          <p class="text-fixed-medium">
            {{ selectedData?.companyName ? selectedData?.companyName : ''
            }}<br />
            {{ selectedData?.line1 ? selectedData?.line1 : '' }}
            {{ selectedData?.line2 ? selectedData?.line2 : '' }}<br />
            {{ selectedData?.town ? selectedData?.town : '' }} ,
            {{
              selectedData?.region?.isocodeShort
                ? selectedData?.region?.isocodeShort
                : ''
            }}
            {{ selectedData?.postalCode ? selectedData?.postalCode : '' }}
          </p>
        </section>
        <section class="col-md-4 mb-4 mb-md-0">
          <h3 class="font-bold">
            PO#<span class="text-fixed-legal-copy"
              >{{ 'addToCartItems.optional' | cxTranslate }}&nbsp;</span
            >
          </h3>

          <div class="c-text-input">
            <input
              type="text"
              class="focus-visible"
              (keyup)="orderNoUpdate()"
              [(ngModel)]="orderNo"
              maxlength="20"
              [ngClass]="{
                invalidClass: orderNo == undefined || orderNo == ''
              }"
            />
          </div>
        </section>
        <section class="col-md-4">
          <div class="row">
            <div class="col-6">
              {{ 'addToCartItems.subtotal' | cxTranslate }}
            </div>
            <div class="col-6 text-right">
              {{ standardCartDetails?.subTotal?.value | currency }}
              {{ 'plp.cad' | cxTranslate }}
            </div>
          </div>
          <div class="row u-border-bottom-black py-1">
            <div class="col-6">
              {{ 'addToCartItems.salesTax' | cxTranslate }}
            </div>
            <div class="col-6 text-right">
              {{ standardCartDetails?.totalTax?.value | currency }}
              {{ 'plp.cad' | cxTranslate }}
            </div>
          </div>
          <div class="row py-1 font-bold">
            <div class="col-6">
              <span
                class="shipTo-Header total-Amount-Span"
                *ngIf="stdCartType; else fluSavings"
                >{{ 'addToCartItems.total' | cxTranslate }}</span
              >
              <ng-template #fluSavings>
                <span class="shipTo-Header total-Amount-Span">
                  {{ 'addToCartItems.total' | cxTranslate }}</span
                >
              </ng-template>
            </div>
            <div class="col-6 text-right">
              {{ standardCartDetails?.totalPriceWithTax?.value | currency }}
              {{ 'plp.cad' | cxTranslate }}
            </div>
          </div>
        </section>
      </section>

      <!-- Product items -->
      <section
        class="row u-border-top-grey py-4 mt-4 mt-md-0"
        *ngFor="let productEntry of productEntries"
      >
        <!-- image -->
        <section class="col-md-2 mb-4">
          <a (click)="productDetails(productEntry)">
            <img
              class="prod-Image"
              *ngIf="productEntry?.PRIMARY !== undefined; else undefinedImg"
              src="{{ productEntry?.PRIMARY?.url }}"
              alt="Product Image"
            />
            <ng-template #undefinedImg>
              <img class="prod-Image" [src]="fallbackImage" alt="" />
            </ng-template>
          </a>
        </section>
        <!-- details -->
        <section class="col-md-10">
          <section class="row c-prod-details">
            <div class="col-md-12">
              <a (click)="productDetails(productEntry)">
                <h2
                  class="font-bold c-heading c-heading-h4 mb-4"
                  [innerHTML]="productEntry?.product?.name"
                ></h2>
              </a>
            </div>

            <div
              class="col-md-12 d-md-flex justify-content-between text-fixed-medium"
            >
              <div>
                <span class="font-bold">{{ 'plp.units' | cxTranslate }}</span>
                <span class="d-inline-block d-sm-none"
                  >:&nbsp;{{ productEntry?.quantity }}</span
                >
                <span class="d-none d-md-block">{{
                  productEntry?.quantity
                }}</span>
              </div>
              <div>
                <span
                  class="font-bold"
                  *ngIf="
                    productEntry?.product?.actualPotency;
                    else notActualPotency
                  "
                  >{{ 'plp.your_price' | cxTranslate }}</span
                >
                <ng-template #notActualPotency>
                  <span class="font-bold">{{
                    'plp.yourPrice' | cxTranslate
                  }}</span>
                </ng-template>
                <span class="d-inline-block d-sm-none"
                  >:&nbsp;{{ productEntry?.basePrice?.value | currency }}
                  {{ 'plp.cad' | cxTranslate }}</span
                >
                <span class="d-none d-md-block"
                  >{{ productEntry?.basePrice?.value | currency }}
                  {{ 'plp.cad' | cxTranslate }}</span
                >
              </div>
              <div>
                <span class="font-bold">{{
                  'addToCartItems.subtotal' | cxTranslate
                }}</span>
                <span class="d-inline-block d-sm-none"
                  >:&nbsp;{{ productEntry?.totalPrice?.value | currency }}
                  {{ 'plp.cad' | cxTranslate }}</span
                >
                <span class="d-none d-md-block"
                  >{{ productEntry?.totalPrice?.value | currency }}
                  {{ 'plp.cad' | cxTranslate }}</span
                >
              </div>
              <div>
                <span class="font-bold">{{
                  'addToCartItems.estDeliveryDate' | cxTranslate
                }}</span>
                <span class="d-inline-block d-sm-none"
                  >:&nbsp;{{
                    productEntry?.estimatedDeliveryDate
                      | slice : 0 : 10
                      | date : dateFormat
                  }}</span
                >
                <span class="d-none d-md-block">{{
                  productEntry?.estimatedDeliveryDate
                    | slice : 0 : 10
                    | date : dateFormat
                }}</span>
              </div>
            </div>
            <section class="col-md-12 text-fixed-medium py-4">
              <div
                class="m-0"
                [innerHTML]="productEntry?.product?.description"
              ></div>
            </section>
            <section class="col-md-12 text-fixed-medium">
              <span class="font-bold"
                >{{ 'plp.productNumber' | cxTranslate }}:&nbsp;</span
              >
              <span class="prdct-No">{{ productEntry?.product?.code }}</span>
            </section>
          </section>
        </section>
      </section>
    </div>

    <!-- Sidebar -->
    <div class="col-md-4 pl-md-4 pl-lg-5">
      <section class="row">
        <!-- Order Total -->
        <ng-container *ngIf="!stdCartType">
          <section class="col-md-12 mb-2 text-center">
            <h4 class="mb-0">
              {{ totalInfluenzaProducts }} | {{ totalInfluenzaDoses }}
              {{ 'plp.doses' | cxTranslate }}
            </h4>
          </section>
        </ng-container>
        <section
          class="col-md-12 py-4 d-flex align-items-center justify-content-center justify-content-md-between flex-column flex-md-row"
        >
          <ng-container *ngIf="stdCartType; else fluCartOrderTotal">
            <h3 class="font-bold mb-0">
              {{ 'addToCartItems.orderTotal' | cxTranslate }}:
            </h3>
            <h1 class="font-bold mb-0">
              {{ standardCartDetails?.totalPriceWithTax?.value | currency }}
              {{ 'plp.cad' | cxTranslate }}
            </h1>
          </ng-container>
        </section>
        <ng-template #fluCartOrderTotal>
          <section
            class="col-md-12 py-4 d-flex align-items-center justify-content-center justify-content-md-between flex-column flex-md-row"
          >
            <h3 class="font-bold mb-0">
              {{ 'addToCartItems.estTotal' | cxTranslate }}:
            </h3>
            <h1 class="font-bold mb-0">
              {{ standardCartDetails?.totalPriceWithTax?.value | currency }}
              {{ 'plp.cad' | cxTranslate }}
            </h1>
          </section>
        </ng-template>

        <!-- Buttons -->
        <section
          class="col-md-12 d-flex justify-content-around justify-content-md-end mb-4"
        >
          <button
            class="c-button c-button--secondary mr-md-3"
            (click)="navigateToCart()"
          >
            {{ 'addToCartItems.editCart' | cxTranslate }}
          </button>
          <button
            class="c-button c-button--primary"
            (click)="checkout()"
            [disabled]="showPlaceOrderBtn"
          >
            {{ 'addToCartItems.continue' | cxTranslate }}
          </button>
        </section>
        <!-- Terms and condition -->
        <section class="col-md-12 mb-4 u-border-bottom-grey pb-4">
          <div *ngIf="stdCartType; else fluCartTerm">
            <app-terms-conditions
              compName="TandC-checkout"
            ></app-terms-conditions>
          </div>
          <ng-template #fluCartTerm>
            <app-terms-conditions
              compName="TandC-fluseasonviewpayment"
            ></app-terms-conditions>
          </ng-template>
        </section>
        <!-- modal for continue  -->
        <ng-template #continueCheckout class="checkout-popover">
          <section class="checkout-modal">
            <div class="modal-dialog">
              <div class="modal-content overlay">
                <div class="close" (click)="modalService.dismissAll()">
                  <i
                    class="fa-solid fa-x c-icon-primary c-icon-primary-sm c-icon-round-hover mr-3 mt-4"
                  ></i>
                </div>
                <div class="modal-header overlay-heading">
                  <h2
                    class="c-heading-36 font-bold u-border-bottom-grey pb-4 w-100"
                    *ngIf="stdCartType; else fluOrderHeader"
                  >
                    {{ 'addToCartItems.readyToPlaceOrder' | cxTranslate }}
                  </h2>
                  <ng-template #fluOrderHeader>
                    <h2
                      class="c-heading-36 font-bold u-border-bottom-grey pb-4 w-100"
                    >
                      {{ 'orderConfirmation.readyToReserve' | cxTranslate }}
                    </h2>
                  </ng-template>
                </div>

                <div class="modal-body confirm-order">
                  <!-- Total  -->
                  <div class="order-total row d-flex align-items-center mb-4">
                    <div class="col-md-5">
                      <h3
                        class="font-bold"
                        *ngIf="stdCartType; else fluEstTotal"
                      >
                        {{ 'addToCartItems.orderTotal' | cxTranslate }}
                      </h3>

                      <ng-template #fluEstTotal>
                        <h3 class="font-bold">
                          {{ 'addToCartItems.estTotal' | cxTranslate }}
                        </h3>
                      </ng-template>
                    </div>
                    <div class="col-md-7 text-sm-left text-md-right">
                      <h2 class="c-heading-36 font-bold">
                        {{
                          standardCartDetails?.totalPriceWithTax?.value
                            | currency
                        }}
                        {{ 'plp.cad' | cxTranslate }}
                      </h2>
                    </div>
                  </div>

                  <!-- Ship -->
                  <div
                    class="ship-location mt-4 pt-4 u-border-top-grey pb-4 u-border-bottom-grey mb-4"
                  >
                    <h3 class="font-bold">
                      {{ 'addToCartItems.shipToLocation' | cxTranslate }}
                    </h3>
                    <p class="mb-2">
                      {{ selectedData?.companyName }}<br />
                      {{ '(' + selectedData?.unitID + ')' }}
                    </p>
                    <p class="mb-2" *ngIf="!stdCartType">
                      {{ selectedData?.line1 ? selectedData?.line1 : '' }} ,
                      {{ selectedData?.line2 ? selectedData?.line2 : '' }}
                      {{ selectedData?.town ? selectedData?.town : '' }} ,
                      {{
                        selectedData?.region?.isocodeShort
                          ? selectedData?.region?.isocodeShort + ','
                          : ''
                      }}
                      {{
                        selectedData?.postalCode ? selectedData?.postalCode : ''
                      }}
                    </p>
                  </div>
                  <div class="row pb-4">
                    <div
                      class="col-md-6"
                      *ngIf="stdCartType; else fluReservationContact"
                    >
                      <h3 class="font-bold">
                        {{ 'addToCartItems.orderContact' | cxTranslate }}
                      </h3>
                      <p class="popupname">{{ orderContact?.name }}</p>
                    </div>
                    <ng-template #fluReservationContact>
                      <div class="col-md-6">
                        <h3 class="font-bold">
                          {{
                            'orderConfirmation.reservationContact' | cxTranslate
                          }}
                        </h3>
                        <p class="con-name">{{ orderContact?.name }}</p>
                        <p class="con-name">
                          {{ orderContact?.fax ? orderContact?.fax : '' }}
                        </p>
                        <a
                          class="email-Value email-outline"
                          *ngIf="orderContact?.email"
                          href="{{ 'mailto:' + orderContact?.email }}"
                          >{{ orderContact?.email }}</a
                        >
                      </div>
                    </ng-template>
                    <div class="col-md-6">
                      <h3 class="font-bold">
                        {{ 'addToCartItems.paymentMethod' | cxTranslate }}
                      </h3>
                      <p id="popup-stdpaymentDetails">
                        {{ displayPaymentMethod }}
                        {{
                          selectedPaymentMethod !== 'BILL_ME_LATER'
                            ? '(*' + selectedcardNumber.substring(6, 10) + ')'
                            : ''
                        }}
                      </p>
                    </div>
                  </div>
                  <!-- Buttons -->
                  <div class="row u-border-top-grey pt-5">
                    <div class="modal-spinner" *ngIf="showPlaceOrderBtn">
                      <i
                        class="fa-solid fa-spinner modal-spinner-animation"
                      ></i>
                    </div>
                    <div class="col-md-6 d-flex justify-content-center">
                      <button
                        type="button"
                        class="c-button c-button--secondary w-100 mb-4"
                        data-toggle="modal"
                        data-target="#overlay"
                        data-di-id="di-id-35119eec-5a7dc742"
                        (click)="editOrder()"
                        *ngIf="stdCartType; else fluEditButton"
                      >
                        {{ 'addToCartItems.editOrder' | cxTranslate }}
                      </button>
                      <ng-template #fluEditButton>
                        <button
                          type="button"
                          class="c-button c-button--secondary w-100 mb-4"
                          data-toggle="modal"
                          data-target="#overlay"
                          data-di-id="di-id-35119eec-5a7dc742"
                          (click)="editOrder()"
                        >
                          {{
                            'orderConfirmation.editReservation' | cxTranslate
                          }}
                        </button>
                      </ng-template>
                    </div>
                    <div class="col-md-6 d-flex justify-content-center">
                      <button
                        type="submit"
                        class="c-button c-button--primary w-100 mb-4"
                        data-toggle="modal"
                        data-target="#loading-order"
                        id="orderConfirmbtn"
                        data-di-id="#orderConfirmbtn"
                        (click)="placeOrder()"
                        *ngIf="stdCartType; else fluButton"
                      >
                        {{ 'addToCartItems.placeOrder' | cxTranslate }}
                      </button>
                      <ng-template #fluButton>
                        <button
                          type="submit"
                          class="c-button c-button--primary w-100 mb-4"
                          data-toggle="modal"
                          data-target="#loading-order"
                          id="orderConfirmbtn"
                          data-di-id="#orderConfirmbtn"
                          (click)="placeOrder()"
                        >
                          {{ 'orderConfirmation.yesReserve' | cxTranslate }}
                        </button>
                      </ng-template>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </ng-template>
      </section>
      <!-- Payment method -->
      <section class="row u-border-bottom-grey pb-4">
        <section class="col-md-12">
          <h3 class="font-bold mb-4">
            {{ 'addToCartItems.paymentMethod' | cxTranslate }}
          </h3>
        </section>
        <section
          class="col-md-12 col-lg-12 col-xl-12 payment-ngSelect-Div payment-drpdwn"
        >
          <ng-select
            class="w-100"
            *ngIf="hidePaymentMethod === false; else onlyBillMeLater"
            [items]="paymentsArray"
            bindLabel="name"
            bindValue="code"
            [multiple]="false"
            name="chosenPaymentMethod"
            [clearable]="false"
            placeholder=""
            [(ngModel)]="chosenPaymentMethod"
            (change)="getPaymentDetailsOfUser($event)"
          >
          </ng-select>
          <ng-template #onlyBillMeLater>
            <h5 class="text-fixed-medium font-bold">
              {{ 'orderConfirmation.billMeLater' | cxTranslate }}
            </h5>
          </ng-template>
        </section>
        <section
          class="col-md-12 col-lg-12 col-xl-12 payment-ngSelect-Div card-list"
          *ngIf="chosenPaymentMethod === 1"
        >
          <ng-select
            class="w-100"
            [items]="userCardDataArray"
            bindLabel="name"
            bindValue="code"
            [multiple]="false"
            name="chosenCard"
            [clearable]="false"
            placeholder=""
            [(ngModel)]="chosenCard"
            (change)="getCardDetailsOfUser($event)"
          >
            <ng-template ng-footer-tmp class="ng-select-footer">
              <!-- <p class="create-new" (click)="openModal($event)">Add a New Card </p> -->
              <ng-option class="create-new" (click)="openModal($event)">
                Add a New Card
              </ng-option>
            </ng-template>
          </ng-select>
        </section>
        <section
          class="container creditCard-Container"
          *ngIf="hidePaymentMethod === false"
        >
          <section class="row">
            <section class="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1">
              <img
                src="/assets/img/VISA.svg"
                class="creditCard-Img"
                alt="visa"
              />
            </section>
            <section class="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1">
              <img src="/assets/img/MC.svg" class="creditCard-Img" alt="mc" />
            </section>
          </section>
        </section>

        <section
          class="col-md-12 col-lg-12 col-xl-12 multiple-Shipments-Msg-Div"
        >
          <span class="text-fixed-legal-copy">{{
            'addToCartItems.paymentNote' | cxTranslate
          }}</span>
        </section>
      </section>

      <section class="row pt-4">
        <!-- Flu Contact -->
        <div *ngIf="!stdCartType">
          <h6
            [ngbPopover]="popContent"
            [autoClose]="false"
            #myPopover="ngbPopover"
            placement="top"
          ></h6>
          <ng-template #popContent>
            <div class="c-popover-black p-4">
              <span>{{ 'orderConfirmation.resCheckMsg' | cxTranslate }}</span>
              <div class="text-center my-2">
                <a class="dismissStyle" (click)="myPopover.close()">{{
                  'orderConfirmation.dismiss' | cxTranslate
                }}</a>
              </div>
            </div>
          </ng-template>
        </div>
        <!-- Standard Contact -->
        <section class="col-md-12 col-lg-12 col-xl-12">
          <section *ngIf="stdCartType; else fluCartContact" class="row mb-4">
            <section class="col-8">
              <h3 class="font-bold">
                {{ 'addToCartItems.orderContact' | cxTranslate }}
              </h3>
            </section>
            <section class="col-4 text-right">
              <a class="c-link mt-2" *ngIf="editNotClicked">
                <span
                  class="edit-Click"
                  (click)="editContact()"
                  *ngIf="editNotClicked"
                >
                  {{ 'addToCartItems.edit' | cxTranslate }}
                </span>
                <fa-icon [icon]="faPenToSquare" class="icon"></fa-icon>
              </a>
            </section>
          </section>
          <ng-template #fluCartContact>
            <div class="row">
              <div class="col-md-12 mb-3">
                <h3 class="font-bold mb-4">
                  {{ 'addToCartItems.reservationContact' | cxTranslate }}
                </h3>
                <p class="mb-0 text-fixed-legal-copy">
                  {{ 'selectVaccines.contactMsg' | cxTranslate }}
                </p>
              </div>
            </div>
          </ng-template>
        </section>
      </section>
      <!-- Contact form -->
      <section class="row" *ngIf="!editNotClicked" [formGroup]="editStdForm">
        <section class="col-md-12 c-text-input mb-3">
          <label class="edit-label">{{
            'addToCartItems.name' | cxTranslate
          }}</label>
          <input
            class="form-control edit-Form-Styling"
            type="text"
            onkeypress="return /[a-zA-Z0-9-' ]+('[a-zA-Z0-9-])?[-a-zA-Z0-9]*$/i.test(event.key)"
            formControlName="firstName"
            [ngClass]="{
              invalidClass:
                editStdForm.get('firstName').invalid &&
                (editStdForm.get('firstName').touched ||
                  editStdForm.get('firstName').dirty)
            }"
          />
          <cx-form-errors *ngIf="!editStdForm.get('firstName').value"
            ><p>
              {{ 'addToCartItems.fieldReq' | cxTranslate }}
            </p></cx-form-errors
          >
          <cx-form-errors
            *ngIf="
              editStdForm.get('firstName').dirty &&
              editStdForm.get('firstName').invalid &&
              editStdForm.get('firstName').value
            "
            ><p>
              {{ 'addToCartItems.invalidName' | cxTranslate }}
            </p></cx-form-errors
          >
        </section>
        <section class="col-md-12 c-text-input mb-3">
          <label class="edit-label">{{
            'addToCartItems.phone' | cxTranslate
          }}</label>
          <input
            class="form-control edit-Form-Styling"
            onkeypress="return /[0-9-]/i.test(event.key)"
            maxlength="12"
            type="text"
            appPhoneMask
            placeholder="xxx-xxx-xxxx"
            formControlName="phone"
            [ngClass]="{
              invalidClass:
                editStdForm.get('phone').invalid &&
                (editStdForm.get('phone').touched ||
                  editStdForm.get('phone').dirty)
            }"
          />
          <cx-form-errors
            *ngIf="
              editStdForm.get('phone').dirty &&
              editStdForm.get('phone').invalid &&
              editStdForm.get('phone').value
            "
            ><p>
              {{ 'addToCartItems.invalidPhoneno' | cxTranslate }}
            </p></cx-form-errors
          >
          <cx-form-errors *ngIf="!editStdForm.get('phone').value"
            ><p>
              {{ 'addToCartItems.fieldReq' | cxTranslate }}
            </p></cx-form-errors
          >
        </section>
        <section class="col-md-12 c-text-input mb-3">
          <label class="edit-label">{{
            'addToCartItems.mobile' | cxTranslate
          }}</label>
          <input
            class="form-control edit-Form-Styling"
            onkeypress="return /[0-9-]/i.test(event.key)"
            maxlength="12"
            placeholder="xxx-xxx-xxxx"
            appPhoneMask
            type="text"
            formControlName="mobile"
            [ngClass]="{
              invalidClass:
                editStdForm.get('mobile').invalid &&
                (editStdForm.get('mobile').touched ||
                  editStdForm.get('mobile').dirty)
            }"
          />
          <cx-form-errors
            class="txt-error"
            *ngIf="
              editStdForm.get('mobile').dirty &&
              editStdForm.get('mobile').invalid
            "
            ><p>
              {{ 'addToCartItems.invalidPhoneno' | cxTranslate }}
            </p></cx-form-errors
          >
        </section>
        <section class="col-md-12 c-text-input mb-3">
          <label class="edit-label">{{
            'addToCartItems.email' | cxTranslate
          }}</label>
          <ng-select
            class="w-100 email-Styling"
            [items]="emailsArray"
            bindLabel="emailId"
            bindValue="emailId"
            [multiple]="false"
            formControlName="emailId"
            [clearable]="false"
            [(ngModel)]="chosenEmail"
          >
          </ng-select>
        </section>
        <section
          class="col-md-12 c-text-input mb-3"
          *ngIf="chosenEmail === 'Add Other E-mail Address'"
        >
          <input
            class="form-control edit-Form-Styling"
            type="email"
            formControlName="newemailId"
            maxlength="241"
            [ngClass]="{
              invalidClass:
                editStdForm.get('newemailId').invalid &&
                (editStdForm.get('newemailId').touched ||
                  editStdForm.get('newemailId').dirty)
            }"
          />
          <span class="trash-div">
            <i
              class="fas fa-trash-alt c-icon-primary c-icon-primary-sm"
              (click)="delEmail()"
            ></i>
          </span>
          <cx-form-errors
            class="txt-error"
            *ngIf="
              editStdForm.get('newemailId').invalid &&
              (editStdForm.get('newemailId').dirty ||
                editStdForm.get('newemailId').touched) &&
              editStdForm.get('newemailId').value !== 0
            "
          >
            <p>You have entered an invalid email address</p>
          </cx-form-errors>
        </section>
        <!-- buttons -->
        <section
          class="col-md-12 d-flex mt-4 justify-content-around justify-content-lg-end"
        >
          <button
            (click)="onCancelClick()"
            class="c-button c-button--secondary mr-lg-2 ml-lg-2"
          >
            {{ 'addToCartItems.cancel' | cxTranslate }}
          </button>
          <button
            (click)="updateOrderContact()"
            class="c-button c-button--primary mr-lg-2 ml-lg-2"
          >
            {{ 'addToCartItems.save' | cxTranslate }}
          </button>
        </section>
      </section>
      <!-- Contact details -->
      <section class="row contact-Main-Row" *ngIf="editNotClicked">
        <section class="col-md-3 mb-2">
          <span class="font-bold">{{
            'addToCartItems.name' | cxTranslate
          }}</span>
        </section>
        <section class="col-md-9">
          <span>{{ orderContact?.name }}</span>
        </section>
        <section class="col-md-3 mb-2" *ngIf="stdCartType">
          <span class="font-bold">{{
            'addToCartItems.phone' | cxTranslate
          }}</span>
        </section>
        <section class="col-md-9" *ngIf="stdCartType">
          <span>{{ orderContact?.phoneNo ? orderContact?.phoneNo : '' }}</span>
        </section>
        <section
          class="col-md-3 mb-2"
          *ngIf="!stdCartType && orderContact?.fax"
        >
          <span class="font-bold">{{
            'selectVaccines.fax' | cxTranslate
          }}</span>
        </section>
        <section class="col-md-9" *ngIf="!stdCartType && orderContact?.fax">
          <span>{{ orderContact?.fax }}</span>
        </section>
        <section class="col-md-3 mb-2" *ngIf="orderContact?.mobileNo">
          <span class="font-bold">{{
            'addToCartItems.mobile' | cxTranslate
          }}</span>
        </section>
        <section class="col-md-9" *ngIf="orderContact?.mobileNo">
          <span>{{ orderContact?.mobileNo }}</span>
        </section>
        <section class="col-md-3 mb-2">
          <span class="font-bold">{{
            'addToCartItems.email' | cxTranslate
          }}</span>
        </section>
        <section class="col-md-9" *ngIf="stdCartType">
          <a
            class="c-force-wrap"
            href="{{ 'mailto:' + orderContact?.email }}"
            >{{ orderContact?.email }}</a
          >
        </section>
        <section class="col-md-9" *ngIf="!stdCartType">
          <a
            class="c-force-wrap"
            href="{{
              'mailto:' +
                (orderContact?.email ? orderContact?.email : userEmail)
            }}"
            >{{ orderContact?.email ? orderContact?.email : userEmail }}</a
          >
        </section>
        <section class="col-md-3 mb-2" *ngIf="!stdCartType">
          <span class="font-bold">{{
            'selectVaccines.placedBy' | cxTranslate
          }}</span>
        </section>
        <section class="col-md-9" *ngIf="!stdCartType">
          <span
            >{{ userFname ? userFname : '' }}
            {{ userLname ? userLname : '' }}</span
          >
        </section>
      </section>

      <!-- Addresses -->
      <section class="row mt-4 text-fixed-medium">
        <section class="col-lg-6 mb-4 mb-lg-0">
          <section class="row">
            <section class="col-md-12">
              <h4 class="font-bold">
                {{ 'addToCartItems.billingAddress' | cxTranslate }}
              </h4>
            </section>
            <section class="col-md-12">
              <span
                >{{ 'addToCartItems.accountNo' | cxTranslate }}:
                {{
                  standardCartDetails?.billingAddress?.unitID
                    ? standardCartDetails?.billingAddress?.unitID
                    : ''
                }}</span
              >
            </section>
            <section class="col-md-12">
              <span>{{
                standardCartDetails?.billingAddress?.companyName
                  ? standardCartDetails?.billingAddress?.companyName
                  : ''
              }}</span>
            </section>
            <section class="col-md-12">
              <span
                >{{
                  standardCartDetails?.billingAddress?.line1
                    ? standardCartDetails?.billingAddress?.line1
                    : ''
                }},
                {{
                  standardCartDetails?.billingAddress?.line2
                    ? standardCartDetails?.billingAddress?.line2
                    : ''
                }}</span
              >
            </section>
            <section class="col-md-12">
              <span
                >{{
                  standardCartDetails?.billingAddress?.town
                    ? standardCartDetails?.billingAddress?.town
                    : ''
                }},
                {{
                  standardCartDetails?.billingAddress?.region?.isocodeShort
                    ? standardCartDetails?.billingAddress?.region?.isocodeShort
                    : ''
                }}
                {{
                  standardCartDetails?.billingAddress?.postalCode
                    ? standardCartDetails?.billingAddress?.postalCode
                    : ''
                }}</span
              >
            </section>
          </section>
        </section>
        <section class="col-lg-6">
          <section class="row">
            <section class="col-md-12 col-lg-12 col-xl-12 sold-Address-Div">
              <h4 class="font-bold">
                {{ 'addToCartItems.soldToAddress' | cxTranslate }}
              </h4>
            </section>
            <section class="col-md-12 col-lg-12 col-xl-12">
              <span
                >{{ 'addToCartItems.accountNo' | cxTranslate }}:
                {{
                  standardCartDetails?.soldtoAddress?.unitID
                    ? standardCartDetails?.soldtoAddress?.unitID
                    : ''
                }}</span
              >
            </section>
            <section class="col-md-12 col-lg-12 col-xl-12">
              <span>{{
                standardCartDetails?.soldtoAddress?.companyName
                  ? standardCartDetails?.soldtoAddress?.companyName
                  : ''
              }}</span>
            </section>
            <section class="col-md-12 col-lg-12 col-xl-12">
              <span
                >{{
                  standardCartDetails?.soldtoAddress?.line1
                    ? standardCartDetails?.soldtoAddress?.line1
                    : ''
                }},
                {{
                  standardCartDetails?.soldtoAddress?.line2
                    ? standardCartDetails?.soldtoAddress?.line2
                    : ''
                }}</span
              >
            </section>
            <section class="col-md-12 col-lg-12 col-xl-12">
              <span
                >{{
                  standardCartDetails?.soldtoAddress?.town
                    ? standardCartDetails?.soldtoAddress?.town
                    : ''
                }},
                {{
                  standardCartDetails?.soldtoAddress?.region?.isocodeShort
                    ? standardCartDetails?.soldtoAddress?.region?.isocodeShort
                    : ''
                }}
                {{
                  standardCartDetails?.soldtoAddress?.postalCode
                    ? standardCartDetails?.soldtoAddress?.postalCode
                    : ''
                }}</span
              >
            </section>
          </section>
        </section>
        <section class="col-md-12 mt-4">
          <a class="c-link" target="_blank" (click)="billingAccount()">
            <span class="manage-Billing-info">{{
              'addToCartItems.billingInfo' | cxTranslate
            }}</span>
          </a>
        </section>
      </section>
    </div>
  </section>
</section>
